.bi-content {
  width: 100vw;
  height: 100vh;
  background: url(https://shuidao-static.qn.shuidao.com/dealer/1079423eec5e06c17bcef538b1a7dff1.png) no-repeat;
  background-size: cover;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.bi-content.inner-page {
  width: 100%;
  height: 100%;
}
.bi-content .bi-header {
  width: 100%;
  height: 125px;
  color: #fff;
  text-align: center;
  padding-top: 15px;
}
.bi-content .bi-header .header-title {
  color: #fff;
  font-size: 40px;
  font-family: Nunito-Bold, Nunito;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 5px;
}
.bi-content .bi-header .header-sub-title {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 1680px) {
  .bi-content .bi-header {
    width: 100%;
    height: 180px;
    color: #fff;
    text-align: center;
    padding-top: 10px;
  }
  .bi-content .bi-header .header-title {
    color: #fff;
    font-size: 60px;
    font-family: Nunito-Bold, Nunito;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 5px;
  }
  .bi-content .bi-header .header-sub-title {
    font-size: 40px;
  }
  .bi-content .page-content .table-title {
    font-size: 20px;
    height: 40px !important;
    line-height: 40px !important;
  }
  .bi-content .page-content .flex-table {
    font-size: 18px;
  }
}
@media (min-width: 1920px) {
  .bi-content .bi-header {
    width: 100%;
    height: 200px;
    color: #fff;
    text-align: center;
    padding-top: 15px;
  }
  .bi-content .bi-header .header-title {
    color: #fff;
    font-size: 60px;
    font-family: Nunito-Bold, Nunito;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 5px;
  }
  .bi-content .bi-header .header-sub-title {
    font-size: 40px;
  }
  .bi-content .page-content .table-title {
    font-size: 28px;
    height: 48px !important;
    line-height: 48px !important;
  }
  .bi-content .page-content .flex-table {
    font-size: 24px;
  }
}
@media (min-width: 3840px) {
  .bi-content .bi-header {
    width: 100%;
    height: 390px;
    color: #fff;
    text-align: center;
    padding-top: 80px;
  }
  .bi-content .bi-header .header-title {
    color: #fff;
    font-size: 100px;
    font-family: Nunito-Bold, Nunito;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .bi-content .bi-header .header-sub-title {
    font-size: 60px;
  }
  .bi-content .page-content .table-title {
    font-size: 36px;
    height: 56px !important;
    line-height: 56px !important;
  }
  .bi-content .page-content .flex-table {
    font-size: 32px;
  }
}
.bi-content .page-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  background: rgba(4, 12, 49, 0.29);
}
.bi-content .page-content .module-wrapper {
  border: 1px solid #3a53cb;
  border-radius: 10px;
  padding: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.bi-content .page-content .module-wrapper.two {
  flex: 2;
}
.bi-content .page-content .module-wrapper.five {
  flex: 5;
}
.bi-content .page-content .page-left {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.bi-content .page-content .page-center {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-left: 12px;
  margin-right: 12px;
}
.bi-content .page-content .page-center.large {
  flex: 3;
}
.bi-content .page-content .page-center .flex-map {
  display: flex;
  flex: 3;
  margin-bottom: 12px;
}
.bi-content .page-content .page-center .flex-map.small {
  flex: 2;
}
.bi-content .page-content .page-center .flex-map.large {
  flex: 3;
}
.bi-content .page-content .page-center .flex-map.super {
  flex: 5;
}
.bi-content .page-content .page-center .flex-chart {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.bi-content .page-content .page-center .flex-chart .line-chart {
  display: flex;
  flex: 1;
}
.bi-content .page-content .page-right {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.bi-content .page-content .chart-wrapper {
  display: flex;
  flex: 1;
}
.bi-content .page-content .table-title {
  color: #fff;
  font-weight: 500;
  height: 30px;
  line-height: 30px;
}
.bi-content .page-content .flex-table {
  color: #fff;
  display: flex;
  flex: 1;
  flex-direction: row;
}
.bi-content .page-content .flex-table.twice {
  flex: 2;
}
.bi-content .page-content .flex-table.five {
  flex: 5;
}
.bi-content .page-content .flex-table .col {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.bi-content .page-content .flex-table .col.two {
  flex: 2;
}
.bi-content .page-content .flex-table .col.three {
  flex: 3;
}
.bi-content .page-content .flex-table .col .cell {
  display: flex;
  flex: 1;
  min-height: 38px;
  flex-direction: row;
  align-items: center;
}
.bi-content .page-content .flex-table .col .cell.col-header {
  font-weight: 500;
  background: #3a53cb;
  padding: 0 2px;
}
.bi-content .page-content .flex-table .col .cell.text-center {
  justify-content: center;
}
.bi-content .page-content .flex-table .col .cell.text-right {
  justify-content: flex-end;
}

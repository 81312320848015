.content-window-card {
  position: relative;
  background: white;
  box-shadow: none;
  bottom: 0;
  left: 0;
  width: auto;
  padding: 0;
}
.content-window-card::after {
  bottom: -4.8px;
  border-color: transparent #fff #fff transparent;
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  content: " ";
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: 0 0;
  border-style: solid;
  border-width: 4.24264069px;
}
.custom-info {
  border: solid 1px #e8e8e8;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.info-top {
  position: relative;
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 10px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.info-middle {
  font-size: 12px;
  padding: 10px 6px;
  line-height: 20px;
}
.ant-collapse-header {
  padding: 12px 16px 12px 20px !important;
}
.ant-collapse-arrow {
  left: 0 !important;
}
.ant-spin-container {
  height: 100%;
}
.ant-spin-nested-loading {
  height: 100%;
}
